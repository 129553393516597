.cursor-pen {
  cursor: url('./assets/cursors/pencil.cur'), auto;
}
.cursor-eraser {
  cursor: url('./assets/cursors/eraser.cur'), auto;
}

.drag {
  cursor: move;
}

.crosshair {
  cursor: crosshair;
}
